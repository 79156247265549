var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: { maskClosable: false, width: "550px", title: "裁剪头像" },
      on: { cancel: _vm.cancel, ok: _vm.handleOk },
      model: {
        value: _vm.open,
        callback: function ($$v) {
          _vm.open = $$v
        },
        expression: "open",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "cropper-content",
          on: { mouseover: _vm.topCenterMouseover },
        },
        [
          _c(
            "div",
            { staticClass: "cropper" },
            [
              _c("vue-cropper", {
                ref: "cropper",
                attrs: {
                  mode: "100% auto",
                  height: _vm.option.height,
                  fixedNumber: _vm.option.fixedNumber,
                  fixed: true,
                  centerBox: true,
                  img: _vm.option.img,
                  outputSize: _vm.option.size,
                  outputType: _vm.option.outputType,
                  info: true,
                  full: _vm.option.full,
                  canMove: _vm.option.canMove,
                  canMoveBox: _vm.option.canMoveBox,
                  original: _vm.option.original,
                  autoCrop: _vm.option.autoCrop,
                  autoCropWidth: _vm.option.autoCropWidth,
                  autoCropHeight: _vm.option.autoCropHeight,
                },
                on: { realTime: _vm.realTime },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "show-preview",
              style: {
                height: _vm.previews.h + "px",
                overflow: "hidden",
                "margin-left": "40px",
              },
            },
            [
              _c("div", { staticClass: "preview", style: _vm.previews.div }, [
                _c("img", {
                  style: _vm.previews.img,
                  attrs: { src: _vm.previews.url },
                }),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "footer-btn",
          on: { mouseover: _vm.topCenterMouseover },
        },
        [
          _c(
            "div",
            { staticClass: "scope-btn" },
            [
              _c("input", {
                staticStyle: { position: "absolute", clip: "rect(0 0 0 0)" },
                attrs: {
                  type: "file",
                  id: "uploads",
                  accept: "image/png, image/jpeg, image/gif, image/jpg",
                },
                on: {
                  change: function ($event) {
                    return _vm.uploadImg($event, 1)
                  },
                },
              }),
              _c("a-button", {
                attrs: { shape: "circle", icon: "plus" },
                on: {
                  click: function ($event) {
                    return _vm.changeScale(1)
                  },
                },
              }),
              _c("a-button", {
                attrs: { shape: "circle", icon: "minus" },
                on: {
                  click: function ($event) {
                    return _vm.changeScale(-1)
                  },
                },
              }),
              _c("a-button", {
                attrs: { shape: "circle", icon: "undo" },
                on: { click: _vm.rotateLeft },
              }),
              _c("a-button", {
                attrs: { shape: "circle", icon: "redo" },
                on: { click: _vm.rotateRight },
              }),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }